<template>
  <div v-if="products && products.length >= 8">
    <div class="ml-0 lg:ml-col pl-0 lg:pl-col pr-0 lg:pr-6 pb-8">
      <Paragraph class="w-full lg:max-w-xs">
        <h2>Die Top-Produkte aus unserem Shop</h2>
        <nuxt-link class="block link-arrow hover:cursor-pointer" to="/shop">
          <span
            class="font-semibold text-sm text-red uppercase hover:text-black transition"
            >Zum Shop</span
          ><Arrow class="arrow red right transition" />
        </nuxt-link>
      </Paragraph>
    </div>
    <div
      class="overflow-x-scroll lg:overflow-x-none lg:overflow-hidden scrolling-touch"
    >
      <div
        class="flex flex-no-wrap lg:flex-wrap self-stretch lg:self-auto mb-3 lg:mb-0 -mx-2"
      >
        <ProductCard
          class="medium wow fadeIn"
          :product="product(1)"
          :href="product(1).url"
          :sale="product(1).is_sale"
          :bestseller="product(1).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(1).image"
            :alt="product(1).title"
          />
          <template slot="name">
            {{ product(1).title }}
          </template>
          <template slot="price">
            {{ product(1).price | currency }}
          </template>
          <template
            v-if="
              product(1).price_old && product(1).price_old > product(1).price
            "
            slot="oldprice"
          >
            {{ product(1).price_old | currency }}
          </template>
        </ProductCard>
        <ProductCard
          class="large wow fadeIn"
          :product="product(2)"
          :href="product(2).url"
          :sale="product(2).is_sale"
          :bestseller="product(2).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(2).image"
            :alt="product(2).title"
          />
          <template slot="name">
            {{ product(2).title }}
          </template>
          <template slot="price">
            {{ product(2).price | currency }}
          </template>
          <template
            v-if="
              product(2).price_old && product(2).price_old > product(2).price
            "
            slot="oldprice"
          >
            {{ product(2).price_old | currency }}
          </template>
        </ProductCard>

        <!-- SPECIAL LAYOUT FOR SMALL BOXES -->

        <div class="small-box hidden lg:block">
          <ProductCard
            class="small wow fadeIn"
            :product="product(3)"
            :href="product(3).url"
            :sale="product(3).is_sale"
            :bestseller="product(3).is_bestseller"
          >
            <img
              class="special-img-lg"
              :src="product(3).image"
              :alt="product(3).title"
            />
            <template slot="name">
              {{ product(3).title }}
            </template>
            <template slot="price">
              {{ product(3).price | currency }}
            </template>
            <template
              v-if="
                product(3).price_old && product(3).price_old > product(3).price
              "
              slot="oldprice"
            >
              {{ product(3).price_old | currency }}
            </template>
          </ProductCard>
          <ProductCard
            class="small wow fadeIn"
            :product="product(4)"
            :href="product(4).url"
            :sale="product(4).is_sale"
            :bestseller="product(4).is_bestseller"
          >
            <img
              class="special-img-lg"
              :src="product(4).image"
              :alt="product(4).title"
            />
            <template slot="name">
              {{ product(4).title }}
            </template>
            <template slot="price">
              {{ product(4).price | currency }}
            </template>
            <template
              v-if="
                product(4).price_old && product(4).price_old > product(4).price
              "
              slot="oldprice"
            >
              {{ product(4).price_old | currency }}
            </template>
          </ProductCard>
        </div>
        <ProductCard
          class="medium wow fadeIn"
          :product="product(5)"
          :href="product(5).url"
          :sale="product(5).is_sale"
          :bestseller="product(5).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(5).image"
            :alt="product(5).title"
          />
          <template slot="name">
            {{ product(5).title }}
          </template>
          <template slot="price">
            {{ product(5).price | currency }}
          </template>
          <template
            v-if="
              product(5).price_old && product(5).price_old > product(5).price
            "
            slot="oldprice"
          >
            {{ product(5).price_old | currency }}
          </template>
        </ProductCard>
        <ProductCard
          class="medium wow fadeIn"
          :product="product(6)"
          :href="product(6).url"
          :sale="product(6).is_sale"
          :bestseller="product(6).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(6).image"
            :alt="product(6).title"
          />
          <template slot="name">
            {{ product(6).title }}
          </template>
          <template slot="price">
            {{ product(6).price | currency }}
          </template>
          <template
            v-if="
              product(6).price_old && product(6).price_old > product(6).price
            "
            slot="oldprice"
          >
            {{ product(6).price_old | currency }}
          </template>
        </ProductCard>
        <ProductCard
          class="large wow fadeIn"
          :product="product(7)"
          :href="product(7).url"
          :sale="product(7).is_sale"
          :bestseller="product(7).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(7).image"
            :alt="product(7).title"
          />
          <template slot="name">
            {{ product(7).title }}
          </template>
          <template slot="price">
            {{ product(7).price | currency }}
          </template>
          <template
            v-if="
              product(7).price_old && product(7).price_old > product(7).price
            "
            slot="oldprice"
          >
            {{ product(7).price_old | currency }}
          </template>
        </ProductCard>
        <ProductCard
          class="medium wow fadeIn"
          :product="product(8)"
          :href="product(8).url"
          :sale="product(8).is_sale"
          :bestseller="product(8).is_bestseller"
        >
          <img
            class="special-img-lg"
            :src="product(8).image"
            :alt="product(8).title"
          />
          <template slot="name">
            {{ product(8).title }}
          </template>
          <template slot="price">
            {{ product(8).price | currency }}
          </template>
          <template
            v-if="
              product(8).price_old && product(8).price_old > product(8).price
            "
            slot="oldprice"
          >
            {{ product(8).price_old | currency }}
          </template>
        </ProductCard>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from '~/components/Paragraph'
import Arrow from '~/components/Arrow'
import ProductCard from '~/components/cardtypes/ProductCard'

export default {
  components: {
    ProductCard,
    Paragraph,
    Arrow,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    product(count) {
      if (this.products[count - 1]) {
        return this.products[count - 1]
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.link-arrow:hover .arrow {
  transform: translateX(5px) rotate(45deg);
  border-color: black;
}

.small-box {
  @apply mx-2;
  min-width: calc(100% - 1rem);
}

@screen lg {
  .small-box {
    @apply m-2;

    min-width: calc(33.33333% - 1rem);
  }
}
</style>
