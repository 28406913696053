<template>
  <div class="bg-grey-lighter py-2 lg:py-6">
    <Container
      class="flex flex-wrap lg:flex-no-wrap justify-start lg:justify-between w-full px-0 lg:px-6 font-semibold text-xs text-grey-darker uppercase"
    >
      <!-- <div class="flex justify-center items-center w-full md:w-1/2 lg:w-1/4">
        bla
      </div> -->
      <div
        class="flex justify-start lg:justify-center items-center w-full md:w-1/2 lg:w-1/4 py-3 lg:py-0"
      >
        <img
          class="h-5 mr-3"
          src="~assets/images/ill/service.svg"
        />Installationsservice
      </div>
      <div
        class="flex justify-start lg:justify-center items-center w-full md:w-1/2 lg:w-1/4 py-3 lg:py-0"
      >
        <img class="h-5 mr-3" src="~assets/images/ill/warranty.svg" />Bis zu 6
        Jahren Garantie
      </div>
      <div
        class="flex justify-start lg:justify-center items-center w-full md:w-1/2 lg:w-1/4 py-3 lg:py-0"
      >
        <img class="h-5 mr-3" src="~assets/images/ill/hands.svg" />Persönlicher
        Support
      </div>
      <div
        class="flex justify-start lg:justify-center items-center w-full md:w-1/2 lg:w-1/4 py-3 lg:py-0"
      >
        <img class="h-5 mr-3" src="~assets/images/ill/package.svg" />Schneller
        Versand
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '~/components/layoutelements/Container'

export default {
  components: {
    Container,
  },
}
</script>
