<template>
  <div class="relative">
    <client-only>
      <carousel
        ref="carousel"
        class="relative bg-grey"
        :autoplay="true"
        :autoplay-timeout="4000"
        :autoplay-hover-pause="true"
        :loop="true"
        :per-page="1"
        :pagination-enabled="false"
      >
        <slide v-for="slide in slides" :key="slide.id" :href="slide.href">
          <AppCarouselSlide home>
            <template slot="title">
              {{ slide.title }}
            </template>
            <template slot="paragraph">
              <!-- eslint-disable-next-line -->
              <div v-html="slide.content" />
            </template>
            <template v-if="slide.link" slot="button">
              <ClassicButtonLink class="mt-5" :href="slide.link">
                Mehr erfahren
              </ClassicButtonLink>
            </template>
            <img
              class="special-img-lg"
              :src="slide.image"
              alt="highlighted product image"
            />
          </AppCarouselSlide>
        </slide>
      </carousel>
    </client-only>

    <!-- NAVIGATION -->

    <div class="slider-nav hidden lg:flex flex-col absolute pin-b pin-r m-25">
      <div class="prev transition" @click.prevent="prevSlide">
        <Arrow class="arrow white left" />
      </div>
      <div class="next transition" @click.prevent="nextSlide">
        <Arrow class="arrow white right" />
      </div>
    </div>
  </div>
</template>

<script>
import AppCarouselSlide from '~/components/carousel/AppCarouselSlide'
import Arrow from '~/components/Arrow'
import ClassicButtonLink from '~/components/ClassicButtonLink'

export default {
  components: {
    AppCarouselSlide,
    Arrow,
    ClassicButtonLink,
  },
  props: {
    slides: {
      type: Array,
      required: true,
    },
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-nav {
  width: 126px;
}

.prev,
.next {
  @apply pt-5 pr-7 pb-6 pl-6 bg-black rounded-sm opacity-85;

  &:hover {
    @apply opacity-100 cursor-pointer;
  }
}

.prev {
  align-self: flex-start;
}

.next {
  align-self: flex-end;
}
</style>
