var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nuxt-link',{staticClass:"block relative overflow-hidden hover:cursor-pointer rounded-sm",class:[
    { 'hover-dark smaller-card m-2 min-h-60 lg:min-h-68': _vm.shop },
    { 'w-full': _vm.fullWidth },
    { 'w-1/2 lg:w-1/3': _vm.shop && !_vm.fullWidth },
    { 'square my-0 lg:my-2 mx-2 min-h-80 lg:h-auto': !_vm.shop },
  ],attrs:{"to":_vm.href}},[_c('div',{staticClass:"w-full h-full",class:[{ 'flex flex-col relative': _vm.shop }, { absolute: !_vm.shop }]},[_c('SaleTag',{attrs:{"sale":_vm.sale,"bestseller":_vm.bestseller,"news":_vm.news}}),_vm._v(" "),(_vm.shop)?_c('div',{staticClass:"border-hover-black absolute z-75 w-full h-full transition-fast"}):_vm._e(),_vm._v(" "),(_vm.shop)?_c('div',{staticClass:"image-container relative self-start overflow-hidden bg-grey-light w-full flex-no-shrink"},[_vm._t("img",function(){return [_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product.image,"alt":_vm.product.title}})]})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full md:w-auto py-4 px-5 text-white flex-grow",class:[
        { 'box md:w-full bg-grey-lighter transition': _vm.shop },
        { 'absolute pin-b pin-l z-70 bg-black': !_vm.shop },
      ]},[_c('Paragraph',{staticClass:"h-full flex flex-col justify-between"},[_c('h3',{class:[{ 'transition text-blue-darker text-base hyphens': _vm.shop }]},[_vm._t("name")],2),_vm._v(" "),_c('p',{staticClass:"prices",class:[{ 'transition text-blue-dark': _vm.shop }]},[_c('span',{staticClass:"font-semibold"},[_vm._t("price")],2),_vm._v(" "),_c('span',{staticClass:"text-blue-light line-through whitespace-no-wrap"},[_vm._t("oldprice")],2)])])],1),_vm._v(" "),(!_vm.shop)?_c('div',{staticClass:"border-hover-black absolute z-65 w-full h-full transition-fast"}):_vm._e(),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }