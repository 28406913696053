<template>
  <nav class="bg-grey-light">
    <div class="flex flex-wrap justify-center items-center">
      <!-- SINGLE MENU ELEMENT -->
      <MenuServiceElement
        v-for="service in services"
        :key="service.id"
        :href="service.url"
      >
        <template slot="normal">
          <div class="h-24 flex flex-col items-center justify-center">
            <img :src="service.icon" alt="service icon" />
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h3
            class="w-full font-semibold tracking-wide text-center uppercase mt-8"
            v-html="service.title"
          />
        </template>
        <template slot="hover">
          <div class="h-16">
            <img class="h-full" :src="service.icon" alt="service icon" />
          </div>
          <p class="mt-5 text-xs leading-custom text-center">
            {{ service.summary }}
          </p>
        </template>
      </MenuServiceElement>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import MenuServiceElement from '~/components/menu/MenuServiceElement'

export default {
  components: {
    MenuServiceElement,
  },
  computed: {
    ...mapState('settings', ['services']),
  },
}
</script>

<style lang="scss" scoped>
h3 {
  @apply font-semibold;

  font-size: 0.75rem;
}

@screen md {
  h3 {
    font-size: 0.9rem;
  }
}
</style>
