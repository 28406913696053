<template>
  <div>
    <div class="ml-0 lg:ml-col pl-0 lg:pl-col pr-0 lg:pr-6 pb-8">
      <Paragraph class="w-full lg:max-w-xs">
        <h2>Unsere Referenzen</h2>
        <nuxt-link
          class="block link-arrow hover:cursor-pointer"
          to="/referenzen"
        >
          <span
            class="font-semibold text-sm text-red uppercase hover:text-black transition"
            >Alle anzeigen</span
          ><Arrow class="arrow red right transition" />
        </nuxt-link>
      </Paragraph>
    </div>
    <div class="overflow-x-scroll lg:overflow-hidden scrolling-touch">
      <div class="-mx-2">
        <div
          class="flex flex-no-wrap lg:flex-wrap self-stretch lg:self-auto mb-3 lg:mb-0"
        >
          <!-- ARTICLE CARD -->

          <ArticleCard
            v-for="reference in references"
            :key="reference.id"
            is-ref
            :href="reference.url"
            class="wow fadeIn"
          >
            <template slot="img">
              <img
                class="special-img-lg"
                :src="reference.image"
                alt="blogpost image"
              />
            </template>
            <template slot="subtitle"> Referenz </template>
            <template slot="title">
              {{ reference.title }}
            </template>

            {{ reference.summary }}
          </ArticleCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from '~/components/Paragraph'
import Arrow from '~/components/Arrow'
import ArticleCard from '~/components/cardtypes/ArticleCard'

export default {
  components: {
    Paragraph,
    ArticleCard,
    Arrow,
  },
  props: {
    references: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.link-arrow:hover .arrow {
  transform: translateX(5px) rotate(45deg);
  border-color: black;
}
</style>
