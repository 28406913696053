<template>
  <div>
    <div class="ml-0 lg:ml-col pl-0 lg:pl-col pr-0 lg:pr-6 pb-8">
      <Paragraph class="w-full lg:max-w-xs">
        <h2>Aktuelles</h2>
        <nuxt-link class="block link-arrow hover:cursor-pointer" to="/blog">
          <span
            class="font-semibold text-sm text-red uppercase hover:text-black transition"
            >Blog anzeigen</span
          ><Arrow class="arrow red right transition" />
        </nuxt-link>
      </Paragraph>
    </div>
    <div class="overflow-x-scroll lg:overflow-hidden scrolling-touch">
      <div
        class="flex flex-no-wrap lg:flex-wrap self-stretch lg:self-auto mb-3 lg:mb-0 -mx-2"
      >
        <!-- ARTICLE CARD -->

        <ArticleCard
          v-for="entry in entries"
          :key="entry.id"
          is-blog
          :href="entry.url"
          class="wow fadeIn"
        >
          <template slot="date">
            {{ entry.date }}
          </template>
          <template slot="img">
            <img
              class="special-img-lg"
              :src="entry.image"
              alt="blogpost image"
            />
          </template>
          <template slot="title">
            {{ entry.title }}
          </template>
          {{ entry.summary }}
        </ArticleCard>
      </div>
    </div>
  </div>
</template>

<script>
import Paragraph from '~/components/Paragraph'
import Arrow from '~/components/Arrow'
import ArticleCard from '~/components/cardtypes/ArticleCard'

export default {
  components: {
    Paragraph,
    Arrow,
    ArticleCard,
  },
  props: {
    entries: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.link-arrow:hover .arrow {
  transform: translateX(5px) rotate(45deg);
  border-color: black;
}
</style>
