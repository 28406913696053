<template>
  <div>
    <!-- HEADER WITH MAINNAV: CONTACT-, WEBSITE- AND SERVICE-NAVIGATION -->

    <header>
      <div>
        <MenuMain />
      </div>
    </header>

    <!-- 1 | FIRST SECTION - PRESENTATION WITH CAROUSEL -->

    <section class="bg-grey-light">
      <HomeCarousel :slides="slider" />
    </section>

    <!-- TRUST BANNER -->

    <TrustBanner />

    <!-- 4 | FOURTH SECTION - BLOG -->

    <section>
      <Container class="py-14">
        <SectionBlog :entries="news" />
      </Container>
    </section>

    <!-- 2 | SECOND SECTION - SHOW CASE WITH TOP PRODUCTS -->

    <section>
      <Container class="pb-14">
        <SectionShowcase :products="products" />
      </Container>
    </section>

    <!-- 3 | THIRD SECTION - REFERENCES -->

    <section>
      <Container class="pb-14">
        <SectionReference :references="references" />
      </Container>
    </section>

    <section class="bg-grey-light">
      <Container class="px-13 py-6 md:p-13">
        <MenuService class="-mx-2" />
      </Container>

      <Container class="pb-14">
        <Paragraph class="flex flex-wrap lg:flex-no-wrap">
          <div
            class="w-full lg:w-1/3 ml-0 lg:ml-col pl-0 lg:pl-col pr-0 lg:pr-6"
          >
            <h2>{{ subtitle }}</h2>
          </div>
          <!-- eslint-disable-next-line -->
          <div class="w-full lg:w-1/2" v-html="content" />
        </Paragraph>
      </Container>
    </section>
  </div>
</template>

<script>
import SectionShowcase from '~/components/sections/SectionShowcase'
import TrustBanner from '~/components/sections/TrustBanner'
import SectionReference from '~/components/sections/SectionReference'
import SectionBlog from '~/components/sections/SectionBlog'
import HomeCarousel from '~/components/carousel/HomeCarousel'
import MenuService from '~/components/menu/MenuService'

import MenuMain from '~/components/menu/MenuMain'
import Container from '~/components/layoutelements/Container'
import Paragraph from '~/components/Paragraph'

// import Arrow from '~/components/Arrow'

export default {
  components: {
    SectionShowcase,
    TrustBanner,
    SectionReference,
    SectionBlog,
    MenuService,
    HomeCarousel,
    MenuMain,
    Container,
    Paragraph,
  },
  async asyncData({ route, $axios }) {
    const result = await $axios.$get(route.path)
    return result
  },
}
</script>
