<template>
  <!-- A CARDTYPE FOR A PRODUCT - FOR HOME AND SHOP PAGE -->
  <nuxt-link
    class="block relative overflow-hidden hover:cursor-pointer rounded-sm"
    :class="[
      { 'hover-dark smaller-card m-2 min-h-60 lg:min-h-68': shop },
      { 'w-full': fullWidth },
      { 'w-1/2 lg:w-1/3': shop && !fullWidth },
      { 'square my-0 lg:my-2 mx-2 min-h-80 lg:h-auto': !shop },
    ]"
    :to="href"
  >
    <div
      class="w-full h-full"
      :class="[{ 'flex flex-col relative': shop }, { absolute: !shop }]"
    >
      <!-- SALETAG -->

      <SaleTag :sale="sale" :bestseller="bestseller" :news="news" />

      <!-- HOVER EFFECT -->

      <div
        v-if="shop"
        class="border-hover-black absolute z-75 w-full h-full transition-fast"
      />

      <div
        v-if="shop"
        class="image-container relative self-start overflow-hidden bg-grey-light w-full flex-no-shrink"
      >
        <slot name="img">
          <img
            class="special-img-lg"
            :src="product.image"
            :alt="product.title"
          />
        </slot>
      </div>

      <div
        class="w-full md:w-auto py-4 px-5 text-white flex-grow"
        :class="[
          { 'box md:w-full bg-grey-lighter transition': shop },
          { 'absolute pin-b pin-l z-70 bg-black': !shop },
        ]"
      >
        <Paragraph class="h-full flex flex-col justify-between">
          <h3
            :class="[{ 'transition text-blue-darker text-base hyphens': shop }]"
          >
            <slot name="name" />
          </h3>
          <p class="prices" :class="[{ 'transition text-blue-dark': shop }]">
            <span class="font-semibold">
              <slot name="price" />
            </span>
            <span class="text-blue-light line-through whitespace-no-wrap">
              <slot name="oldprice" />
            </span>
          </p>
        </Paragraph>
      </div>

      <!-- HOVER EFFECT -->

      <div
        v-if="!shop"
        class="border-hover-black absolute z-65 w-full h-full transition-fast"
      />
      <slot />
    </div>
  </nuxt-link>
</template>

<script>
import SaleTag from '~/components/SaleTag'
import Paragraph from '~/components/Paragraph'

export default {
  components: {
    SaleTag,
    Paragraph,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
    sale: {
      type: Boolean,
      default: false,
    },
    bestseller: {
      type: Boolean,
      default: false,
    },
    news: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.image-container {
  height: 129px;

  @screen sm {
    height: 182px;
  }
}
// DIFFERENT SIZES FOR DIFFERENT BOXES

.square:after {
  @apply block m-0;

  content: '';
}

.square.small {
  @apply w-full m-0;

  &:after {
    padding-bottom: 25%;
  }

  &:first-of-type {
    @apply mb-6;
  }
}

.square.medium,
.square.large {
  min-width: calc(70% - 1rem);
  &:after {
    @apply pb-full;
  }
}

@screen lg {
  .square.medium {
    min-width: calc(33.33333% - 1rem);
  }
  .square.large {
    min-width: calc(66.66666% - 1rem);
    &:after {
      padding-bottom: 50%;
    }
  }
  .square.small {
    &:after {
      padding-bottom: 50%;
    }
  }
}

// BORDER ANIMATION

.border-hover:hover {
  @apply bg-white-opacity;
}

h3 {
  @apply mb-2 leading-custom;
}

h4 {
  @apply mb-0;
}

.hover-dark:hover {
  h3,
  p {
    @apply text-white;
  }

  .box {
    @apply bg-black;
  }
}

.smaller-card {
  .box {
    @apply w-full self-end overflow-hidden;
  }

  h3 {
    @apply block overflow-hidden;
    height: 38px;
    font-size: 0.95rem;
  }

  .prices {
    @apply overflow-hidden;
    font-size: 0.8rem;
  }
}
</style>
