var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.products && _vm.products.length >= 8)?_c('div',[_c('div',{staticClass:"ml-0 lg:ml-col pl-0 lg:pl-col pr-0 lg:pr-6 pb-8"},[_c('Paragraph',{staticClass:"w-full lg:max-w-xs"},[_c('h2',[_vm._v("Die Top-Produkte aus unserem Shop")]),_vm._v(" "),_c('nuxt-link',{staticClass:"block link-arrow hover:cursor-pointer",attrs:{"to":"/shop"}},[_c('span',{staticClass:"font-semibold text-sm text-red uppercase hover:text-black transition"},[_vm._v("Zum Shop")]),_c('Arrow',{staticClass:"arrow red right transition"})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"overflow-x-scroll lg:overflow-x-none lg:overflow-hidden scrolling-touch"},[_c('div',{staticClass:"flex flex-no-wrap lg:flex-wrap self-stretch lg:self-auto mb-3 lg:mb-0 -mx-2"},[_c('ProductCard',{staticClass:"medium wow fadeIn",attrs:{"product":_vm.product(1),"href":_vm.product(1).url,"sale":_vm.product(1).is_sale,"bestseller":_vm.product(1).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(1).image,"alt":_vm.product(1).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(1).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(1).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(1).price_old && _vm.product(1).price_old > _vm.product(1).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(1).price_old))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('ProductCard',{staticClass:"large wow fadeIn",attrs:{"product":_vm.product(2),"href":_vm.product(2).url,"sale":_vm.product(2).is_sale,"bestseller":_vm.product(2).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(2).image,"alt":_vm.product(2).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(2).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(2).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(2).price_old && _vm.product(2).price_old > _vm.product(2).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(2).price_old))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"small-box hidden lg:block"},[_c('ProductCard',{staticClass:"small wow fadeIn",attrs:{"product":_vm.product(3),"href":_vm.product(3).url,"sale":_vm.product(3).is_sale,"bestseller":_vm.product(3).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(3).image,"alt":_vm.product(3).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n            "+_vm._s(_vm.product(3).title)+"\n          ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.product(3).price))+"\n          ")]),_vm._v(" "),(
              _vm.product(3).price_old && _vm.product(3).price_old > _vm.product(3).price
            )?_c('template',{slot:"oldprice"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.product(3).price_old))+"\n          ")]):_vm._e()],2),_vm._v(" "),_c('ProductCard',{staticClass:"small wow fadeIn",attrs:{"product":_vm.product(4),"href":_vm.product(4).url,"sale":_vm.product(4).is_sale,"bestseller":_vm.product(4).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(4).image,"alt":_vm.product(4).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n            "+_vm._s(_vm.product(4).title)+"\n          ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.product(4).price))+"\n          ")]),_vm._v(" "),(
              _vm.product(4).price_old && _vm.product(4).price_old > _vm.product(4).price
            )?_c('template',{slot:"oldprice"},[_vm._v("\n            "+_vm._s(_vm._f("currency")(_vm.product(4).price_old))+"\n          ")]):_vm._e()],2)],1),_vm._v(" "),_c('ProductCard',{staticClass:"medium wow fadeIn",attrs:{"product":_vm.product(5),"href":_vm.product(5).url,"sale":_vm.product(5).is_sale,"bestseller":_vm.product(5).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(5).image,"alt":_vm.product(5).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(5).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(5).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(5).price_old && _vm.product(5).price_old > _vm.product(5).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(5).price_old))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('ProductCard',{staticClass:"medium wow fadeIn",attrs:{"product":_vm.product(6),"href":_vm.product(6).url,"sale":_vm.product(6).is_sale,"bestseller":_vm.product(6).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(6).image,"alt":_vm.product(6).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(6).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(6).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(6).price_old && _vm.product(6).price_old > _vm.product(6).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(6).price_old))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('ProductCard',{staticClass:"large wow fadeIn",attrs:{"product":_vm.product(7),"href":_vm.product(7).url,"sale":_vm.product(7).is_sale,"bestseller":_vm.product(7).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(7).image,"alt":_vm.product(7).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(7).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(7).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(7).price_old && _vm.product(7).price_old > _vm.product(7).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(7).price_old))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('ProductCard',{staticClass:"medium wow fadeIn",attrs:{"product":_vm.product(8),"href":_vm.product(8).url,"sale":_vm.product(8).is_sale,"bestseller":_vm.product(8).is_bestseller}},[_c('img',{staticClass:"special-img-lg",attrs:{"src":_vm.product(8).image,"alt":_vm.product(8).title}}),_vm._v(" "),_c('template',{slot:"name"},[_vm._v("\n          "+_vm._s(_vm.product(8).title)+"\n        ")]),_vm._v(" "),_c('template',{slot:"price"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(8).price))+"\n        ")]),_vm._v(" "),(
            _vm.product(8).price_old && _vm.product(8).price_old > _vm.product(8).price
          )?_c('template',{slot:"oldprice"},[_vm._v("\n          "+_vm._s(_vm._f("currency")(_vm.product(8).price_old))+"\n        ")]):_vm._e()],2)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }