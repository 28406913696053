<template>
  <nuxt-link
    class="block relative overflow-hidden w-1/2 md:w-1/3 lg:w-1/6 h-60 bg-white my-2 lg:my-0 mx-2 rounded-sm transition hover:cursor-pointer hyphens"
    :to="href"
    @mouseenter="activateTab('hover')"
    @mouseleave="activateTab('normal')"
  >
    <div
      class="border-hover-black absolute z-1010 w-full h-full transition-fast"
    />
    <transition-group tag="div" name="fade">
      <!-- NORMAL STATE -->
      <div
        v-show="isActive('normal')"
        key="normal"
        class="absolute z-1005 w-full bg-white"
      >
        <div class="flex flex-col justify-center items-center pt-12 px-2">
          <slot name="normal" />
        </div>
      </div>

      <!-- HOVER STATE -->
      <div
        v-show="isActive('hover')"
        key="hover"
        class="absolute z-1000 w-full bg-white"
      >
        <div class="flex flex-col justify-center items-center py-6 px-5">
          <slot name="hover" />
        </div>
      </div>
    </transition-group>
  </nuxt-link>
</template>

<script>
export default {
  components: {},

  props: {
    href: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeTab: 'normal',
    }
  },
  methods: {
    isActive(tab) {
      return this.activeTab === tab
    },
    activateTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>
