var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nuxt-link',{staticClass:"block article-card relative overflow-hidden mx-2 bg-grey-lighter hover:bg-black hover:text-white hover:cursor-pointer transition",class:[
    {
      'min-w-80 md:min-w-100 lg:min-w-0 lg:w-1/3 min-h-106 lg:min-h-131':
        _vm.isRef,
    },
    { 'min-w-80 lg:min-w-0 lg:w-1/4 min-h-80 lg:min-h-90': _vm.isBlog },
  ],attrs:{"to":_vm.href}},[_c('div',{staticClass:"absolute pin-t pin-l z-90 py-4 px-5 bg-black font-semibold text-2xs tracking-wide uppercase text-white",class:[{ hidden: _vm.isRef }, { '': _vm.isBlog }]},[_vm._t("date")],2),_vm._v(" "),_c('div',{staticClass:"border-hover-black absolute z-80 w-full h-full transition-fast"}),_vm._v(" "),_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"relative overflow-hidden h-half"},[_vm._t("img")],2),_vm._v(" "),_c('Paragraph',{staticClass:"p-7"},[(_vm.isRef)?_c('h4',{staticClass:"text-red"},[_vm._t("subtitle")],2):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"text-xl lg:text-2xl"},[_vm._t("title")],2),_vm._v(" "),_c('div',{staticClass:"text-sm"},[_vm._t("default")],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }