<template>
  <!-- A CARDTYPE FOR HIGHLITHED POSTS ON THE HOMEPAGE - BLOG AND REFERENCES -->
  <nuxt-link
    :class="[
      {
        'min-w-80 md:min-w-100 lg:min-w-0 lg:w-1/3 min-h-106 lg:min-h-131':
          isRef,
      },
      { 'min-w-80 lg:min-w-0 lg:w-1/4 min-h-80 lg:min-h-90': isBlog },
    ]"
    class="block article-card relative overflow-hidden mx-2 bg-grey-lighter hover:bg-black hover:text-white hover:cursor-pointer transition"
    :to="href"
  >
    <div
      :class="[{ hidden: isRef }, { '': isBlog }]"
      class="absolute pin-t pin-l z-90 py-4 px-5 bg-black font-semibold text-2xs tracking-wide uppercase text-white"
    >
      <slot name="date" />
    </div>

    <!--HOVER EFFECT -->

    <div
      class="border-hover-black absolute z-80 w-full h-full transition-fast"
    />

    <!-- CONTENT -->

    <div class="h-full">
      <div class="relative overflow-hidden h-half">
        <slot name="img" />
      </div>
      <Paragraph class="p-7">
        <h4 v-if="isRef" class="text-red">
          <slot name="subtitle" />
        </h4>
        <h2 class="text-xl lg:text-2xl">
          <slot name="title" />
        </h2>
        <div class="text-sm">
          <slot />
        </div>
      </Paragraph>
    </div>
  </nuxt-link>
</template>

<script>
import Paragraph from '~/components/Paragraph'

export default {
  components: {
    Paragraph,
  },

  props: {
    isBlog: {
      type: Boolean,
      default: false,
    },
    isRef: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
h4 {
  margin-bottom: 0.5rem;
}
</style>
